<template>
  <div class='realtime'>
    <div class="title">
      <h3>{{info.siteName}} {{info.roomName}}-实时画面</h3>
      <el-button class="btn_info" @click="$router.go(-1)">返回</el-button>
    </div>
    <div class="btns">
      <el-button class="btn_info" @click="preview()">预览</el-button>
      <el-button class="btn_info" @click="download">插件下载</el-button>
      <span>（若无插件请点击此按钮进行插件下载并安装）</span>
    </div>
    <div>
      <base-camera :appkey="appkey" :secret="secret" :ip="ip" :port="port" :playMode="0" layout="1x1" ref="camera"></base-camera>
    </div>
  </div>
</template>

<script>
// import TimeVideo from './components/video.vue'
import { mapGetters } from 'vuex'
export default {
  // components: { TimeVideo },
  data () {
    return {
      info: {},
      appkey: '',
      secret: '',
      ip: '',
      port: ''
    };
  },
  created () {
    this.init()
  },
  mounted () {

  },
  computed: {
    ...mapGetters(['domainName'])
  },
  methods: {
    // 初始化数据
    init () {
      this.info = JSON.parse(this.$Base64.decode(this.$route.query.info))
      switch (this.domainName) {
        case '庆元县':
          this.appkey = '20819438'
          this.secret = 'IstSHPoKzDJCoIyjc1Jm'
          this.ip = '8.136.144.154'
          this.port = 4443
          break;
        case '温州市':
          this.appkey = '25938883'
          this.secret = '1aDmQXsVJh1EgEoF31Cf'
          this.ip = '47.111.228.167'
          this.port = 4443
          break;
        case '上城区':
          if (this.info.iscId === 5) {
            this.appkey = '22460026'
            this.secret = 'a9IK1qITKMSiuSYdamVG'
            this.ip = '118.178.57.33'
            this.port = 4443
          } else {
            this.appkey = '24983629'
            this.secret = '9vpX1XpElVmWbn4gGAHR'
            this.ip = '39.107.227.244'
            this.port = 443
          }

          break;
        case '永嘉县':
          this.appkey = '25938883'
          this.secret = '1aDmQXsVJh1EgEoF31Cf'
          this.ip = '47.111.228.167'
          this.port = 4443
          break;
        case '绍兴市':
          this.appkey = '27949473'
          this.secret = 'Roi6FIfvdw0QtOpn77Hl'
          this.ip = '115.29.189.189'
          this.port = 4443

          break;
      }

    },
    preview () {
      // console.log(this.info.indexCode, this.appkey, this.secret)
      this.$refs.camera.startPreview(this.info.indexCode)
    },
    // 插件下载
    download () {
      window.open("https://mainimg.terabits.cn/VideoWebPlugin.exe", '_self')
    }
  }
};
</script>

<style scoped lang="scss">
.realtime {
  margin: 30px 0 30px 30px;
  padding: 20px 20px 10px;
  height: 78vh;
  background: url("../../assets/body.png") no-repeat;
  background-size: 100% 100%;
  h3 {
    color: #30c6fe;
    font-size: 20px;
    font-weight: bold;
  }
  .title {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  .btns {
    display: flex;
    align-items: center;
    margin: 20px 80px;
    span {
      color: #fff;
      font-size: 14px;
    }
  }
  ::v-deep .video {
    // margin: 0 auto;
    width: 80%;
    height: 60vh;
  }
}
</style>
